import React from 'react';

import styled from '@emotion/styled';
import { Flex } from '@/components/base/flex';
import { TextLink } from '@/components/base/text-link';
import { Box } from '@/components/base/box';

import { Text } from '@/components/base/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBox,
  faTableCellsLarge,
  faRssSquare,
} from '@fortawesome/free-solid-svg-icons';
import { useHouseholdDinner } from '@/hooks/use-household-dinner';
import { formatDate, getTodayDate, isSameDate } from '@/utils/format-date';
import { useMealPlanCalendarStore } from '@/store/meal-plan-calendar-store';

export type HomeMenuProps = {};

const StyledWrapper = styled(Flex)`
  height: 100%;
  min-height: 535px;
  display: flex;
  justify-content: center;
`;

const StyledTextLink = styled(TextLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  cursor: pointer;
`;

type StyledIconWrapProps = {
  disabled?: boolean;
};
const StyledIconWrap = styled(Flex)<StyledIconWrapProps>`
  height: 67px;
  width: 67px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.homeMenuIconWrapDisabledBackground
      : theme.colors.homeMenuIconWrapBackground};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii['50%']};
  font-size: ${({ theme }) => theme.fontSizes['5xl']};
  cursor: pointer;
  margin-right: ${({ theme }) => theme.space.s}px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: 1px 2px 1px
      ${({ theme }) => theme.colors.homeMenuIconWrapShadow};
  }
`;

const StyledTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.homeMenuTitle};
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.s};
  text-align: left;
  text-transform: uppercase;
  width: 155px;
  cursor: pointer;
`;

const StyledSubtitle = styled(Text)`
  display: block;
  text-transform: Capitalize;
  font-size: ${({ theme }) => theme.fontSizes['4xs']};
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.homeMenuSubTitle};
  cursor: pointer;
`;

export const HomeMenu: React.FC<HomeMenuProps> = ({}) => {
  const { dinners = [] } = useHouseholdDinner(getTodayDate(), getTodayDate());
  const dinner =
    dinners.find((d) => isSameDate(d.date, getTodayDate() as Date)) ?? null;

  const openRecipeBox = useMealPlanCalendarStore(
    (state) => state?.setRecipeBoxSearch,
  );

  return (
    <StyledWrapper marginTop={'2xl'}>
      <Box>
        <StyledTextLink
          href={dinner ? `/dinners/${formatDate(dinner.date)}` : '#'}
        >
          <StyledIconWrap disabled={!dinner}>
            <i className="icon icon-meal-profiles"></i>
          </StyledIconWrap>

          <Box>
            <StyledTitle>Tonight&apos;s Recipes</StyledTitle>
            <StyledSubtitle>Let&apos;s get cooking!</StyledSubtitle>
          </Box>
        </StyledTextLink>

        <StyledTextLink href={'/plan'}>
          <StyledIconWrap>
            <i className="icon icon-planner"></i>
          </StyledIconWrap>

          <Box>
            <StyledTitle>Meal Planner</StyledTitle>
            <StyledSubtitle>Plan Your Meals</StyledSubtitle>
          </Box>
        </StyledTextLink>

        <StyledTextLink href={'/shopping_lists'}>
          <StyledIconWrap>
            <i className="icon icon-shopping-list"></i>
          </StyledIconWrap>

          <Box>
            <StyledTitle>Grocery List</StyledTitle>
            <StyledSubtitle>Check Your Shopping List</StyledSubtitle>
          </Box>
        </StyledTextLink>

        <StyledTextLink href={'/pantry'}>
          <StyledIconWrap>
            <FontAwesomeIcon icon={faTableCellsLarge} />
          </StyledIconWrap>

          <Box>
            <StyledTitle>Pantry</StyledTitle>
            <StyledSubtitle>Tame Your Kitchen</StyledSubtitle>
          </Box>
        </StyledTextLink>

        <StyledTextLink
          href={'#'}
          onClick={(e) => {
            e.preventDefault();
            openRecipeBox({ keyword: '' });
          }}
        >
          <StyledIconWrap>
            <FontAwesomeIcon icon={faBox} />
          </StyledIconWrap>

          <Box>
            <StyledTitle>Recipe Box</StyledTitle>
            <StyledSubtitle>Discover New Recipes</StyledSubtitle>
          </Box>
        </StyledTextLink>

        <StyledTextLink href={'https://wp2.dinnertime.com/content/table-talk/'}>
          <StyledIconWrap>
            <FontAwesomeIcon icon={faRssSquare} />
          </StyledIconWrap>

          <Box>
            <StyledTitle>Blog</StyledTitle>
            <StyledSubtitle>Read Insightful Posts</StyledSubtitle>
          </Box>
        </StyledTextLink>
      </Box>
    </StyledWrapper>
  );
};
